import { Fragment, useRef, useState } from 'react';

import Link from 'next/link';

import {
  Box,
  BoxProps,
  Flex,
  Grid,
  GridItem,
  Input,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Portal,
  ScaleFade,
  Stack,
} from '@chakra-ui/react';
import { Dollar, LocationPlus, PurchaseTag, Time } from '@styled-icons/boxicons-regular';

import RelativeTimeDisplay from '@common/RelativeTimeDisplay';
import ShimmerImage from '@common/ShimmerImage';
import { Trip } from '@types';
import { epochToDate, formatCurrency, getTripSlug, getTripTypeAsString } from '@utils/index';

import TripActions from '../TripActions';
import UserAvatar from '../UserAvatar';
import TripField from './TripField';
import TripTag from './TripTag';

interface Props extends BoxProps {
  trip: Trip;
  stopSelfRender?: boolean;
  isPopup?: boolean;
}

const TripCard: React.FC<Props> = (props) => {
  const { trip, stopSelfRender, isPopup, ...rest } = props;
  const ref = useRef<HTMLInputElement>(null);
  const [isHover, setIsHover] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsHover(false);
      setIsFadingOut(false);
    }, 220);
  };

  const locationText = () => {
    if (!isPopup) return `${trip.destinations.length} điểm đến`;
    const visibleLocations = trip.destinations.slice(0, 3).map((d) => d.name);
    const andText = trip.destinations.length > 3 ? ` và ${trip.destinations.length - 3} địa điểm khác` : '';
    return visibleLocations.join(', ') + andText;
  };

  return (
    <Box>
      <Popover isOpen={isHover || isFadingOut} placement="bottom" isLazy initialFocusRef={ref}>
        <PopoverAnchor>
          <div />
        </PopoverAnchor>
        <Box
          as="article"
          boxShadow="lg"
          borderRadius="10px"
          height="fit-content"
          onMouseEnter={handleMouseEnter}
          {...rest}
        >
          <ShimmerImage borderRadius="10px 10px 0 0" height="250px" width="100%" src={trip.thumbnail} alt={trip.name} />
          <Stack padding="4" direction="column" spacing="2">
            <Box as="h2" noOfLines={2} height="2.5em">
              {trip.name}
            </Box>
            <Flex fontSize="12px" alignItems="center">
              <UserAvatar user={trip.owner} />
              <RelativeTimeDisplay
                as="p"
                ml="auto"
                color="gray.600"
                fontWeight="700"
                date={epochToDate(trip.createdAt || 0)}
              />
            </Flex>
            <TripField key="price" icon={<Dollar size="24px" />}>
              {formatCurrency(trip.price)}
            </TripField>
            <TripField key="type" icon={<Time size="24px" />}>
              {getTripTypeAsString(trip)}
            </TripField>
            <TripField
              key="location count"
              icon={<LocationPlus size="24px" />}
              alignItems={isPopup ? 'flex-start' : 'center'}
            >
              {locationText()}
            </TripField>
            {isPopup && (
              <Grid templateColumns="repeat(3, 1fr)" gap="2">
                {trip.destinations.slice(0, 3).map((destination) => (
                  <GridItem key={destination.name} w="100%" h="80px">
                    <ShimmerImage
                      width="100%"
                      height="100%"
                      borderRadius="8px"
                      objectFit="cover"
                      src={destination.thumbnail}
                      alt={destination.name}
                    />
                  </GridItem>
                ))}
              </Grid>
            )}
            <TripField key="tag" icon={<PurchaseTag size="24px" />}>
              {trip.tags?.map((tag, index) => {
                const isFirst = index === 0;
                const isLast = index === trip.tags.length - 1;
                const tagId = `trip-tag-${trip.id}-${index}`;
                return (
                  <Fragment key={tagId}>
                    <TripTag tag={tag} {...(isFirst ? { pl: '0' } : {})} />
                    {!isLast && <Box as="span">·</Box>}
                  </Fragment>
                );
              })}
            </TripField>
            <TripActions trip={trip} justifyContent="space-between" />
          </Stack>
        </Box>

        {!stopSelfRender && (isHover || isFadingOut) && (
          <Portal>
            <PopoverContent
              position="absolute"
              transform="translate(-65%, -2.5%) !important"
              border="none !important"
              bg="transparent !important"
              boxShadow="none !important"
              onMouseLeave={handleMouseLeave}
            >
              <ScaleFade
                in={isHover && !isFadingOut}
                transition={{
                  enter: { duration: 0.3, delay: 0.2, ease: 'easeInOut' },
                  exit: { duration: 0.2, ease: 'easeInOut' },
                }}
                onMouseLeave={handleMouseLeave}
              >
                <Input display="none" ref={ref} />
                <Link href={`/trips/${getTripSlug(trip)}`} legacyBehavior>
                  <a>
                    <TripCard trip={trip} stopSelfRender isPopup bg="white" width="135%" boxShadow="2xl" />
                  </a>
                </Link>
              </ScaleFade>
            </PopoverContent>
          </Portal>
        )}
      </Popover>
    </Box>
  );
};

export default TripCard;
