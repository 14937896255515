import { useEffect, useRef } from 'react';

import { useAuthModalContext } from '@contexts/AuthModalContext';
import { setTripActionState } from '@redux/tripActionsReducer';

import { Trip } from '@types';
import { isBookmarkedTrip, isLikedTrip } from '@utils/index';

import { useAppDispatch, useAppSelector } from '.';

const useTripActions = (trip: Trip) => {
  const { user } = useAppSelector((state) => state.auth);
  const didMount = useRef(false);

  const { like, likeCount, bookmark } = useAppSelector((state) => state.tripActions);

  const {
    store: { isRestoring },
  } = useAuthModalContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setTripActionState({
        tripId: trip.id,
        isLiked: isLikedTrip(trip.likeMap, user?.uid),
        like: trip.likeCount,
        isBookmarked: isBookmarkedTrip(trip.bookmarkMap, user?.uid),
      }),
    );
  }, [isRestoring]);

  useEffect(() => {
    if (user?.uid && didMount) {
      trip.likeMap = trip.likeMap || {};
      trip.likeCount = likeCount[trip.id];
      trip.likeMap[user.uid] = like[trip.id];
    }
  }, [like, likeCount]);

  return { like, likeCount, bookmark };
};

export default useTripActions;
