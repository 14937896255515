import { useEffect, useState } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import { formatRelativeDateTime } from '@utils/index';

interface Props extends BoxProps {
  date: Date;
  children?: string | React.ReactNode;
}

const ONE_MINUTE = 60 * 1000;

const RelativeTimeDisplay: React.FC<Props> = (props) => {
  const { date, ...rest } = props;
  const [timeString, setTimeString] = useState(formatRelativeDateTime(date));
  useEffect(() => {
    const updateTimeString = setInterval(() => {
      setTimeString(formatRelativeDateTime(date));
    }, ONE_MINUTE);
    return () => clearInterval(updateTimeString);
  }, [date]);
  return (
    <Box {...rest}>
      {props.prefix}
      {timeString}
      {props.children}
    </Box>
  );
};

export default RelativeTimeDisplay;
