import { Box, BoxProps } from '@chakra-ui/react';

import styles from '@styles/export.module.scss';

interface Props extends BoxProps {
  tag: string;
}

const TripTag: React.FC<Props> = ({ tag, ...rest }) => {
  return (
    <Box
      as="span"
      padding="1"
      fontWeight="400"
      color={styles.colorPrimaryBlack}
      cursor="pointer"
      _hover={{
        color: styles.colorSecondaryYellow,
      }}
      {...rest}
    >
      {tag}
    </Box>
  );
};

export default TripTag;
