import { Avatar, AvatarProps, Box, BoxProps, Flex } from '@chakra-ui/react';

import { SUser } from '@types';

interface Props {
  user: SUser;
  size?: string;
  avatarProps?: AvatarProps;
  boxProps?: BoxProps;
}

const UserAvatar: React.FC<Props> = ({ user, size = '24px', avatarProps, boxProps }) => {
  return (
    <Flex gap="2" alignItems="center">
      <Avatar height={size} width={size} name={user?.photoURL || ''} {...avatarProps} />
      <Box as="p" fontWeight="700" {...boxProps}>
        {user?.name || user?.displayName || 'Người dùng'}
      </Box>
    </Flex>
  );
};

export default UserAvatar;
