import { MouseEventHandler } from 'react';

import { Box, Flex, FlexProps, IconButton } from '@chakra-ui/react';
import { Bookmark as OutlinedBookmark, Heart as OutlinedHeart, Share } from '@styled-icons/boxicons-regular';
import { Heart as SolidHeart, Bookmark as SolidBookmark } from '@styled-icons/boxicons-solid';

import { useAppDispatch } from '@hooks/index';
import useTripActions from '@hooks/use-trip-actions';
import { addToBookmark, likeTrip } from '@redux/tripActionsReducer';

import { Trip } from '@types';

interface Props extends FlexProps {
  trip: Trip;
  onLike?: MouseEventHandler<HTMLButtonElement>;
  onShare?: MouseEventHandler<HTMLButtonElement>;
  onBookmark?: MouseEventHandler<HTMLButtonElement>;
}

const TripActions: React.FC<Props> = (props) => {
  const { trip, onLike, onShare, onBookmark, ...rest } = props;
  const { like, likeCount, bookmark } = useTripActions(trip);
  const dispatch = useAppDispatch();

  return (
    <Flex gap="2" {...rest}>
      <Flex gap="2" alignItems="center">
        <IconButton
          aria-label="Like"
          size="md"
          variant="outlineIcon"
          onClick={(e) => {
            e.preventDefault();
            dispatch(likeTrip(trip.id));
            onLike?.(e);
          }}
        >
          {like[trip.id] ? <SolidHeart size="20px" color="black" /> : <OutlinedHeart size="20px" color="black" />}
        </IconButton>
        <Box as="h3">{likeCount[trip.id]}</Box>
      </Flex>
      <Flex gap="2">
        <IconButton aria-label="Share" size="md" variant="outlineIcon" onClick={onShare}>
          <Share size="20px" color="black" />
        </IconButton>
        <IconButton
          aria-label="Bookmark"
          size="md"
          variant="outlineIcon"
          onClick={(e) => {
            e.preventDefault();
            dispatch(addToBookmark(trip.id));
            onBookmark?.(e);
          }}
        >
          {bookmark[trip.id] ? (
            <SolidBookmark size="20px" color="black" />
          ) : (
            <OutlinedBookmark size="20px" color="black" />
          )}
        </IconButton>
      </Flex>
    </Flex>
  );
};

export default TripActions;
