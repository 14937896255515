import { Box, Flex, FlexProps } from '@chakra-ui/react';

interface Props extends FlexProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  iconSize?: string;
}

const TripField: React.FC<Props> = ({ children, icon, ...rest }) => {
  return (
    <Flex alignItems="center" {...rest}>
      <Box width={rest.iconSize || '24px'} height={rest.iconSize || '24px'}>
        {icon}
      </Box>
      <Box as="p" pl="2" fontSize="14px">
        {children}
      </Box>
    </Flex>
  );
};

export default TripField;
